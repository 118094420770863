* {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.sub-header {
  display: flex;
  justify-content: center;
}
.disabled-image {
  opacity: 0.4;
}
.hide {
  display: none;
}
.disabled-image:hover + .hide {
  display: block;
  color: red;
  margin: 2rem;
}

.spinner {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
